import React from 'react'
import { Grid, Typography, GridList, GridListTile, Link } from '@material-ui/core';
import TeamCard from '../../molecule/cards/teamCard'
import { makeStyles } from '@material-ui/core/styles';
import * as team from '../../utils/constants/team';
import backgroundTeam from '../../img/backgroundTeam.png'


const useStyles = makeStyles({
    root: {
        backgroundImage: `url(${backgroundTeam})`,
        width: "100%",
    },
    subtitle1: {
        color: "#031586",
        fontWeight: "bold",
        fontSize: "1.8em",
        marginBottom: "4vh",
        marginTop: "8vh",
        textAlign: "left",
    },
    textTeam: {
        textAlign: "left",
        color: "#5f5f5f",
        marginTop: "8vh",
        fontSize: "1.4em",
    },
    titleTeam: {
        color: "#495057",
        fontSize: "2.4em",
        letterSpacing: "1.5px",
        marginTop: "8vh",
        fontWeight: "bold",
    },
    buttonTeam: {
        color: "#fff",
        backgroundImage: "linear-gradient(103deg, #010B4A, #00A5DF)",
        textSize: "15px",
        textTransform: 'none',
        '&:hover': {
            backgroundImage: "linear-gradient(103deg, #00A5DF, #010B4A)",
        },
    },


    rootGridListParent: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        paddingRight: '5vw',
    },
    gridTile: {
        height: "100vh",
        width: "100vw"
    }
});




function TeamMobile(props) {
    const classes = useStyles();

    return (

        <Grid container justify="center" id="equipe" className={classes.root}>

            <Grid container item xs={9}>

                <Grid container align="column">

                    <Grid container>
                        <Grid container item md={4} xs={12}>
                            <Typography className={classes.titleTeam}>
                                Nossa Equipe
                            </Typography>
                        </Grid>
                        <Grid container item md={7} xs={12}>
                            <Typography className={classes.textTeam}>
                                Um dos diferenciais da iniciativa ExACTa é sua equipe altamente qualificada, com formação sólida e ampla experiência prática.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid >
                        <Typography className={classes.subtitle1}>
                            Coordenação
                        </Typography>
                    </Grid>

                    <div className={classes.rootGridListParent}>
                        <GridList className={classes.gridList} container cols={1.1} cellHeight="auto">
                            {
                                team.COORDENATORS.map(coordenator => (
                                    <GridListTile className={classes.gridTile}>
                                        <Link href={coordenator.linkedin} target="_blank">
                                            <TeamCard
                                                image={window.location.origin + coordenator.image}
                                                title={coordenator.title}
                                                subtitle={coordenator.subtitle}
                                                description={coordenator.description}
                                                linkedin={coordenator.linkedin}
                                            />
                                        </Link>
                                    </GridListTile>
                                ))
                            }
                        </GridList>
                    </div>

                    <Grid >
                        <Typography className={classes.subtitle1}>
                            Líderes de Equipe
                    </Typography>
                    </Grid>
                    <div className={classes.rootGridListParent}>
                        <GridList className={classes.gridList} container cols={1.1} cellHeight="auto">
                            {
                                team.SPECIALISTS.map(specialist => (
                                    <GridListTile className={classes.gridTile}>
                                        <Link href={specialist.linkedin} target="_blank">
                                            <TeamCard
                                                image={window.location.origin + specialist.image}
                                                title={specialist.title}
                                                subtitle={specialist.subtitle}
                                                description={specialist.description}
                                                linkedin={specialist.linkedin}
                                            />
                                        </Link>
                                    </GridListTile>
                                ))
                            }
                        </GridList>
                    </div>

                    <Grid >
                        <Typography className={classes.subtitle1}>
                            Agentes de Transformação Digital
                    </Typography>
                    </Grid>
                    <div className={classes.rootGridListParent}>
                        <GridList className={classes.gridList} container cols={1.1} cellHeight="auto">
                            {
                                team.DEVS.map(dev => (
                                    <GridListTile className={classes.gridTile}>
                                        <Link href={dev.linkedin} target="_blank">
                                            <TeamCard
                                                image={window.location.origin + dev.image}
                                                title={dev.title}
                                                subtitle={dev.subtitle}
                                                description={dev.description}
                                                linkedin={dev.linkedin}
                                            />
                                        </Link>
                                    </GridListTile>
                                ))
                            }
                        </GridList>
                    </div>

                </Grid>
            </Grid>
        </Grid>
    );
}

export default TeamMobile;
