import React from 'react'
import { Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import imageFoto from '../../img/sobreComSombra.png';


const useStyles = makeStyles(theme => ({
    about: {
        paddingBottom: "5vh",
        paddingTop: "5vh",
    },
    aboutContent: {
        maxWidth: "1200px",
        flexFlow: "row",
        "& #img-about": {
            transition: 'all 1.5s ease-in-out'
          },
        "& #paper-about": {
            transition: 'all 1.5s ease-in-out'
          },
          "&:hover #img-about": {
            transform: 'translateX(-15%) translateY(10%)',
          },
          "&:hover #paper-about": {
            transform: 'translateX(15%)',
          }
    },
    paperAbout: {
        width: "40vw",
        backgroundColor: "#fafafa",
        textAlign: "left",
        marginRight: "-70px",
        padding: "4%",
        boxShadow: "5px 5px 15px -1px #726d6d",
    },
    aboutPaperGrid: {
        zIndex: "50",
    },
    imageAbout: {
        marginTop: "5vh",
    },
    textSubtitle: {
        color: "#4b5981",
        letterSpacing: "0.5px",
        fontSize: "1.8em",
        lineHeight: "18px",
        marginTop: "10px",
        marginBottom: "15px",
        fontWeight: "bold",
    },
    textAbout: {
        marginTop: "15px",
        paddingTop: "0px",
        color: "#495057",
        fontSize: "1.3em",
    },
    lineAbout: {
        width: "40px",
        borderBottom: "1px solid #1a0c58",
        color: "#333",

    },
    aboutTitle: {
        fontSize: "2.2em",
        fontWeight: "bold",
    },
}))


function About(props) {

    const classes = useStyles();

    return (
        <Grid container className={classes.about} id="about" justify="center" >
            <Grid item container className={classes.aboutContent} md={9} xs={12} >
                <Grid item className={classes.aboutPaperGrid} md={9} xs={10}>
                    <Paper className={classes.paperAbout} id="paper-about">
                        <Typography className={classes.aboutTitle}>
                            Sobre a ExACTa
                        </Typography >
                        <Typography className={classes.textSubtitle} >
                            Experimentação Ágil. Cocriação. Transformação Digital.
                        </Typography>
                        <div className={classes.lineAbout}></div>
                        <Typography className={classes.textAbout}>
                            ExACTa é uma iniciativa da PUC-Rio de experimentação ágil e cocriação para transformação digital. A iniciativa foi criada por quatro professores do quadro principal do Departamento de Informática, pesquisadores das áreas de Ciência de Dados, Engenharia de Software, Otimização e Interação Humano Computador. A visão da iniciativa é estreitar os laços entre pesquisa e desenvolvimento, permitindo aplicar resultados de pesquisa para desencadear inovação e excelência operacional nas empresas parceiras.
                            <br /> <br />
                            Entre os diferenciais da iniciativa estão a forte integração com pesquisa de ponta sendo desenvolvida no Departamento de Informática da PUC-Rio (conceito CAPES máximo em pesquisa na área de computação, 7), o processo próprio e inovador para a cocriação de soluções de pesquisa e desenvolvimento com agilidade, a equipe altamente qualificada e o ambiente especialmente projetado para este fim. A iniciativa ExACTa permite aplicar na prática resultados de pesquisa gerados tanto no contexto da iniciativa quanto em laboratórios temáticos do Departamento de Informática, como o DASLAB, GALGOS, IDEIAS e Software Science Lab. Com estes diferenciais, ExACTa configura a iniciativa ideal para a transformação digital das empresas parceiras.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid container item  >
                    <Grid item className={classes.imageAbout} >
                        <img src={imageFoto} width="500px" alt="imagem escritório ExACTa" id="img-about" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default About;
