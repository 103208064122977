import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    
    }));

function ScrollTop(props) {
    const { children, window } = props;
    const classes = useStyles();
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#header');

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className={classes.root}>
                {children}
            </div>
        </Zoom>
    );
}

export default function ScrollButtonTop(props) {
    return (
        <ScrollTop {...props}>
            <Fab
                border={1}
                style={{
                    minHeight: "30px",
                    width: "30px",
                    height: "30px",
                    border: "solid",
                    borderWidth: "2px",
                    borderColor: "#010b4a",
                    backgroundColor: "transparent",
                    color: "#000",
                    "&:hover": {
                        backgroundColor: "#f00"
                    },
                }} aria-label="scroll back to top">
                <KeyboardArrowUpIcon style={{ fontSize: "18px" }} />
            </Fab>
        </ScrollTop>
    )
}