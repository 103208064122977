import React from 'react'
import { Grid, Typography, Link } from '@material-ui/core';
import TeamCard from '../../molecule/cards/teamCard'
import { makeStyles } from '@material-ui/core/styles';
import * as team from '../../utils/constants/team';
import backgroundTeam from '../../img/backgroundTeam.png'
import SlideTeam from '../../organism/slide/slideTeam';


const useStyles = makeStyles({
    root: {
        backgroundImage: `url(${backgroundTeam})`,
        width: "100%",
    },
    subtitle1: {
        color: "#031586",
        fontWeight: "bold",
        fontSize: "1.8em",
        marginBottom: "10%",
        marginTop: "25%",
    },
    textTeam: {
        textAlign: "left",
        color: "#5f5f5f",
        fontSize: "1.4em",
    },
    titleTeam: {
        color: "#495057",
        fontSize: "2.4em",
        letterSpacing: "1.5px",
        fontWeight: "bold",
    },
    buttonTeam: {
        color: "#fff",
        backgroundImage: "linear-gradient(103deg, #010B4A, #00A5DF)",
        textSize: "15px",
        textTransform: 'none',
        '&:hover': {
            backgroundImage: "linear-gradient(103deg, #00A5DF, #010B4A)",
        },
    },
    buttonTeamGrid: {
        marginTop: "5%",
    },
    textHeaderTeam:{
        paddingTop: "10vh",
        paddingBottom: "3vh",
    },
    gridTitleTeam: {
        paddingBottom: "5vh",
    },

    rootGridListParent: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        paddingRight: '5vw',
        overflow: 'hidden',
    },
    gridTile: {
        height: "100vh",
        width: "100vw",
    },

    buttons: {
        display: "flex",
        justifyContent: "space-between",
    },
    button: {
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        color: "white",
        margin: "0 8px"
    },
    buttonsContainer: {
        position: "absolute",
        width: "100%",
        zIndex: "100"
    },

});


function Team(props) {
    const classes = useStyles();

    return (
        <Grid container justify="center" id="equipe" className={classes.root}>
            <Grid container item xs={9}>
                <Grid container item className={classes.textHeaderTeam} xs={12} justify={"space-between"}>
                    <Grid container item lg={5} md={4} xs={9} justify={"flex-start"} className={classes.gridTitleTeam}>
                        <Typography className={classes.titleTeam}>
                            Nossa Equipe
                        </Typography>
                    </Grid>
                    <Grid container item lg={7} md={8} xs={9} justify={"flex-end"}>
                        <Typography className={classes.textTeam} >
                            Um dos diferenciais da iniciativa ExACTa é sua equipe altamente qualificada, com formação sólida e ampla experiência prática.
                            </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography className={classes.subtitle1}>
                        Coordenação
                    </Typography>
                </Grid>
                <Grid container item spacing={2}>
                    {
                        team.COORDENATORS.map((coordenator, index) => (
                            <Grid item md={3} sm={6} xs={12} key={index}>
                                <Link href={coordenator.linkedin} target="_blank">
                                    <TeamCard
                                        image={window.location.origin + coordenator.image}
                                        title={coordenator.title}
                                        subtitle={coordenator.subtitle}
                                        description={coordenator.description}
                                        linkedin={coordenator.linkedin}
                                    />
                                </Link>
                            </Grid>
                        ))
                    }
                </Grid>

                <Grid item>
                    <Typography className={classes.subtitle1}>
                        Líderes de Equipe
                    </Typography>
                </Grid>
                <Grid container  justify="center" className={classes.slideTeam}>
                    <SlideTeam time={team.SPECIALISTS}/>
                </Grid>
                 <Grid item >
                    <Typography className={classes.subtitle1}>
                        Agentes de Transformação Digital
                    </Typography>
                 </Grid> 
                 <Grid container  justify="center" className={classes.slideTeam}>
                    <SlideTeam time={team.DEVS}/>
                </Grid> 
            </Grid>
        </Grid>

    );
}

export default Team;
