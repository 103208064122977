import React from 'react'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VisionCard from '../../molecule/cards/visionCard';
import * as vision from '../../utils/constants/vision';

const useStyles = makeStyles(theme => ({
    cards: {
        position: "relative",
        zIndex: "100",
        top: '-11vh',
    },
    visionCards: {
        flex: 1,

    }
}))

function Visions(props) {

    const classes = useStyles();

    return (
        <Grid container className={classes.cards} justify="center">
            <Grid container item md={9} xs={12} justify="center">
                <Grid container item spacing={4} justify="center">
                    {
                        vision.VISIONS.map((visions, index) => (
                            <Grid item sm={8} md={4} xs={8} key={index}>
                                <VisionCard
                                    image={window.location.origin + visions.image}
                                    title={visions.title}
                                    description={visions.description}
                                    className={classes.visionCards}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Visions;
