import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Link as LinkScroll } from 'react-scroll';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: 'white',
    '@media (min-width: 769px)': {
      marginTop: "3vh",
    },
  },
  inline: {
    display: 'inline'
  },
  flex: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center'
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  productLogo: {
    display: 'inline-block',
    borderLeft: `1px solid ${theme.palette.grey['A100']}`,
    marginLeft: 32,
    paddingLeft: 24,
    [theme.breakpoints.up('md')]: {
      paddingTop: '1.5em'
    }
  },
  tagline: {
    display: 'inline-block',
    marginLeft: 10,
    [theme.breakpoints.up('md')]: {
      paddingTop: '0.8em'
    }
  },
  iconContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  iconButton: {
    float: 'right'
  },
  tabContainer: {
    marginLeft: 32,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  tabItem: {
    fontSize: "1.3em",
    padding: 10,
    cursor: 'pointer',
    "&:hover": {
      color: '#0082f3',
    },
    "&.active": {
      color: "#010b4a",
      borderTop: '1px solid #010b4a'
    }
  },
  tabItemMobile: {
    fontSize: "1.3em",
    padding: 10,
    cursor: 'pointer',
    "&:hover": {
      color: '#0082f3',
    },
  },
  menuText: {
    textTransform: 'none',
  },
  menuScroll: {
    minHeight: "auto",
  },
  appBarScroll: {
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: 'white',
  },
}))

function Topbar(props) {

  const [state, setState] = useState({
    inactive: false,
    menuDrawer: false,
    status: "",
    scrolled: 0,
  });


  const mobileMenuOpen = (event) => {
    setState({ ...state, menuDrawer: true });
  }

  const mobileMenuClose = (event) => {
    setState({ ...state, menuDrawer: false });
  }

  const handleSetActive = () => {
    if (state.scrolled === 0) {
      setState({ ...state, inactive: true, status: "header" });
    }
  }

  useEffect(() => {
    document.addEventListener("scroll", e => {
      setState({ ...state, scrolled: document.scrollingElement.scrollTop });
    })
  }, []);

  const classes = useStyles();

  const [work, setWork] = useState("False");


  fetch("workWithUsFlag.json")
      .then(res => res.json())
      .then(data => setWork(data["Disabled"]));

  return (

    <AppBar position="absolute" color="default" className={state.scrolled >= 70 ? classes.appBarScroll : classes.appBar}>
      <Toolbar className={state.scrolled >= 70 ? classes.menuScroll : ""}>
        <Grid container spacing={10} alignItems="baseline">
          <Grid item xs={12} className={classes.flex}>
            {!props.noTabs && (
              <React.Fragment>
                <div className={classes.iconContainer}>
                  <IconButton
                    onClick={mobileMenuOpen}
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="Menu"
                  >
                    <MenuIcon />
                  </IconButton>
                </div>
                <div className={classes.tabContainer}>
                  <SwipeableDrawer
                    anchor="right"
                    open={state.menuDrawer}
                    onClose={mobileMenuClose}
                    onOpen={mobileMenuOpen}
                  >
                    <AppBar title="Menu" />

                    <LinkScroll
                      onClick={mobileMenuClose}
                      to="header"
                      spy={true}
                      smooth={true}
                      offset={-130}
                      duration={600}
                      className={classes.tabItemMobile}
                    >
                      Inicial
                    </LinkScroll>
                    <LinkScroll
                      onClick={mobileMenuClose}
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-130}
                      duration={600}
                      className={classes.tabItemMobile}
                    >
                      Sobre
                    </LinkScroll>
                    <LinkScroll
                      onClick={mobileMenuClose}
                      to="lineOfAction"
                      spy={true}
                      smooth={true}
                      offset={-130}
                      duration={600}
                      className={classes.tabItemMobile}
                    >
                      Atuação
                    </LinkScroll>
                    <LinkScroll
                      onClick={mobileMenuClose}
                      to="equipe"
                      spy={true}
                      smooth={true}
                      offset={-130}
                      duration={600}
                      className={classes.tabItemMobile}
                    >
                      Equipe
                    </LinkScroll>
                    <LinkScroll
                      onClick={mobileMenuClose}
                      to="publication"
                      spy={true}
                      smooth={true}
                      offset={-130}
                      duration={600}
                      className={classes.tabItemMobile}
                    >
                      Publicações
                    </LinkScroll>
                    {work === "False" ? 
                      <LinkScroll
                        onClick={mobileMenuClose}
                        to="work"
                        spy={true}
                        smooth={true}
                        offset={-130}
                        duration={600}
                        className={classes.tabItemMobile}
                      >
                        Trabalhe Conosco
                      </LinkScroll>
                    : "" }
                    <LinkScroll
                      onClick={mobileMenuClose}
                      to="address"
                      spy={true}
                      smooth={true}
                      offset={-130}
                      duration={600}
                      className={classes.tabItemMobile}
                    >
                      Mapa
                    </LinkScroll>
                  </SwipeableDrawer>

                  <Grid container justify="space-between">
                    <LinkScroll
                      to="header"
                      spy={true}
                      smooth={true}
                      offset={-130}
                      duration={600}
                      // corrigir inicialização errada marcando esse elemento como ativo
                      className={state.status === "header" ? classes.tabItem + " active" : classes.tabItem}
                    >
                      Inicial
                    </LinkScroll>
                    <LinkScroll
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-90}
                      duration={600}
                      className={classes.tabItem}
                    >
                      Sobre
                    </LinkScroll>
                    <LinkScroll
                      to="lineOfAction"
                      spy={true}
                      smooth={true}
                      offset={-90}
                      duration={600}
                      className={classes.tabItem}
                      // corrigir inicialização errada que marca esse elemento como ativo
                      onSetActive={handleSetActive}
                      activeClass={state.inactive === true ? "inactive" : ""}
                    >
                      Atuação
                    </LinkScroll>
                    <LinkScroll
                      to="equipe"
                      spy={true}
                      smooth={true}
                      offset={-90}
                      duration={600}
                      className={classes.tabItem}
                    >
                      Equipe
                    </LinkScroll>
                    <LinkScroll
                      to="publication"
                      spy={true}
                      smooth={true}
                      offset={-90}
                      duration={600}
                      className={classes.tabItem}
                    >
                      Publicações
                    </LinkScroll>
                    {work === "False" ? 
                      <LinkScroll
                        to="work"
                        spy={true}
                        smooth={true}
                        offset={-90}
                        duration={600}
                        className={classes.tabItem}
                      >
                        Trabalhe Conosco
                      </LinkScroll>
                    : "" }
                    <LinkScroll
                      to="address"
                      spy={true}
                      smooth={true}
                      offset={-90}
                      duration={600}
                      className={classes.tabItem}
                    >
                      Mapa
                    </LinkScroll>
                  </Grid>
                </div>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Topbar;