// coordenação (ordem alfabética)
import helio from '../../../img/team/helio.png';
import kalinowski from '../../../img/team/kalinowski.png';
import poggi from '../../../img/team/poggi.png';
import simone from '../../../img/team/simone.png';

// líderes de equipe (ordem alfabética)
import adriano from '../../../img/team/adriano.png'
import ariane from '../../../img/team/ariane.png'
import fernando from '../../../img/team/fernando.png'
import jacques from '../../../img/team/jacques.png';
import leonardo from '../../../img/team/leonardo.png';
import patrick from '../../../img/team/patrick.png'
import rodrigo from '../../../img/team/rodrigo.png';

// agentes de transformação digital (ordem alfabética)
import alysson from '../../../img/team/alysson.png';
import anaCarolina from '../../../img/team/anaCarolina.png';
import andreDavys from '../../../img/team/andreDavys.png';
import andreDucca from '../../../img/team/andreDucca.png';
import assis from '../../../img/team/assis.png';
import bruna from '../../../img/team/bruna.png';
import cassia from '../../../img/team/cassia.png';
import debora from '../../../img/team/debora.png';
import dieinison from '../../../img/team/dieinison.png';
import eduardo from '../../../img/team/eduardo.png';
import filipe from '../../../img/team/filipe.png';
import georges from '../../../img/team/georges.png';
import hugo from '../../../img/team/hugo.png';
import joao from '../../../img/team/joao.png';
import kid from '../../../img/team/kid.png';
import pedro from '../../../img/team/pedro.png';
import rafael from '../../../img/team/rafael.png';

// ordem alfabética
export const SPECIALISTS = [
    {
        image: adriano,
        title: "Dr. Adriano Branco",
        subtitle:"Líder de Projetos e Pesquisa",
        description:"Pesquisador e mentor em programas de inovação tecnológica. Especializado em sistemas distribuídos, concentra suas pesquisas em IoT e redes de sensores sem fio. Trabalhou por um ano em uma posição de pós-doutorado no Dipartimento di Elettronica, Informazione e Bioingegneria (DEIB) no Politecnico di Milano. Desde a graduação, atuou como engenheiro eletrônico e desenvolvedor de sistemas no CBPF/CNPq (Brasil) e CERN (Suíça). Trabalhou por mais de 12 anos em projetos de consultoria de integração de sistemas para grandes empresas, principalmente nas indústrias de Siderurgia e Telecomunicações, incluindo um projeto internacional em Manila/Filipinas.",
        linkedin:"https://www.linkedin.com/in/adrianobranco/",
    },
    {
        image: ariane,
        title: "Dra. Ariane Bueno",
        subtitle:"Líder de UX/UI",
        description:"Doutora em Informática pela PUC-Rio na área de Interação Humano-Computador (IHC) e Visualização de Dados, graduada em Engenharia da Computação e com mestrado em Informática pela PUC-Rio na área de IHC. Além da experiência em IHC, atuou profissionalmente como consultora em projetos de UX/UI. Suas pesquisas também envolvem Visualização da Informação com ênfase no estudo do letramento da visualização de dados.",
        linkedin:"https://www.linkedin.com/in/arianebueno/",
    },
    {
        image: fernando,
        title: "Dr. Fernando Correia",
        subtitle:"Líder de Projetos e Pesquisa",
        description:"Doutor em Informática pela PUC-Rio, onde também recebeu seu título de Mestre em Informática e é Bacharel em Engenharia da Computação. Possui vasta experiência na exploração de dados, atuando principalmente em projetos de pesquisa aplicada focados nas áreas de Processamento de Linguagem Natural, Extração de Informação e Aprendizado de Máquina.",
        linkedin:"https://www.linkedin.com/in/fernandocorreiajr/",
    },
    {
        image: jacques,
        title: "Dr. Jacques Chueke",
        subtitle:"Líder de UX/UI",
        description:"Doutor em Ciência da Computação pela City University of London, na área de Interação Humano-Computador. Professor do Departamento de Artes e Design da PUC-Rio. Suas áreas de expertise envolvem o projeto de UX/UI para sistemas de software, área em que frequentemente oferece cursos de extensão pela PUC-Rio. Atuou no campo profissional dentro e fora do país, concebendo soluções de UX/UI para sistemas de software de diferentes domínios de aplicação.",
        linkedin: "https://www.linkedin.com/in/jchueke/",
        
    },
    {
        image: leonardo,
        title: "Leonardo Loures",
        subtitle:"Líder de Infraestrutura",
        description:"Formado em Engenharia da Computação e com cursos de extensão nas áreas de Ciência da Dados e Inteligência Artificial. Possui experiência atuando em projetos de desenvolvimento na área de óleo e gás.",
        linkedin: "https://www.linkedin.com/in/leonardo-loures-79a48986/"
    },
    {
        image: patrick,
        title: "Dr. Patrick Happ",
        subtitle:"Líder de Projetos e Pesquisa",
        description:"Doutor em Engenharia Elétrica pela PUC-Rio, com período sanduíche na Universidade de Pavia (Itália). Possui ampla experiência em projetos de pesquisa e desenvolvimento, principalmente nas áreas de Visão Computacional, Aprendizado Profundo, Aprendizado de Máquina, Sensoriamento Remoto e Computação em Nuvem.",
        linkedin: "https://www.linkedin.com/in/patrick-happ/"
    },
    {
        image: rodrigo,
        title: "Rodrigo Lima",
        subtitle:"Líder de Projetos",
        description:"Formado em Informática pela PUC-Rio, certificado em Scrum Master e Product Owner na Scrum Association, com mestrado em andamento em Informática pela PUC-Rio e com cursos de extensão nas áreas de Ciência de Dados e Aprendizado de Máquina. Possui grande experiência na construção e manutenção de sistemas de informação nos segmentos de Seguros, Telecomunicações, Manufatura e Óleo e Gás, atuando em diversas plataformas, Mainframe, Unix e AS400.",
        linkedin: "https://www.linkedin.com/in/rodrigo-lima26",
    },
];

export const COORDENATORS = [
    {
        image: helio,
        title: "Dr. Hélio Lopes",
        subtitle:"Prof. Quadro Principal PUC-Rio",
        description:"Coordena grupos de pesquisa do Departamento de Informática na área de Ciência de Dados, Computação Gráfica e Otimização e Raciocínio Automático. Tem aplicado técnicas matemáticas e computacionais, incluindo soluções de aprendizado de máquina, para resolver problemas reais de relevância indústrial e científica.",
        linkedin: "http://www-di.inf.puc-rio.br/~lopes/",
    },
    {
        image: kalinowski,
        title: "Dr. Marcos Kalinowski",
        subtitle:"Prof. Quadro Principal PUC-Rio",
        description:"Coordena grupos de pesquisa do Departamento de Informática na área de Engenharia de Software. Sua pesquisa possui foco em problemas e necessidades da indústria de software e envolve a obtenção de conhecimentos e a criação de novas tecnologias (métodos, técnicas e ferramentas) para apoiar entregas de software com agilidade e qualidade.",
        linkedin: "http://www-di.inf.puc-rio.br/~kalinowski/",
    },
    {
        image: poggi,
        title: "Dr. Marcus Poggi",
        subtitle:"Prof. Quadro Principal PUC-Rio",
        description:"Coordena grupos de pesquisa do Departamento de Informática nas áreas de Ciência de Dados, Otimização e Raciocínio Automático e Teoria da Computação. Sua pesquisa possui foco em métodos de resolução para problemas de otimização combinatória, na aplicação destes métodos e em sua implementação.",
        linkedin: "http://www-di.inf.puc-rio.br/~poggi/"
    },
    {
        image: simone,
        title: "Dra. Simone Barbosa",
        subtitle:"Profa. Quadro Principal PUC-Rio",
        description:"Coordena grupos de pesquisa do Departamento de Informática nas áreas de Interação Humano-Computador e Ciência de Dados. Seus atuais interesses de pesquisa envolvem: projeto de sistemas interativos baseados em modelo; ciência de dados, visualização da informação e visual analytics; storytelling digital; e o aumento da qualidade de uso de sistemas interativos utilizando técnicas de inteligência artificial.",
        linkedin: "http://www-di.inf.puc-rio.br/~simone/",
    },
];

export const DEVS = [
    {
        image: alysson,
        title: "Alysson Gomes",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Formado em Sistemas de Informação e com mestrado em Informática pela PUC-Rio na área de Ciência de Dados. Possui experiência em banco de dados relacionais e não relacionais, processamento de linguagem natural e web semântica.",
        linkedin: "https://www.linkedin.com/in/alysson-gomes-sousa/",
    },
    {
        image: anaCarolina,
        title: "Ana Carolina Junger",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Formada em Engenharia da Computação, com mestrado em andamento em Informática pela PUC-Rio na área de Ciência de Dados. Possui experiência prática como desenvolvedora front-end e back-end.",
        linkedin: "https://www.linkedin.com/in/anacarolinajunger/",
    },
    {
        image: andreDavys,
        title: "André Davys",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Formado em Ciência da Computação, com mestrado em Informática pela PUC-Rio na área de Otimização e Raciocínio Automático, e com doutorado em andamento na PUC-Rio na mesma área. Possui experiência prática em projetos de Aprendizado de Máquina aplicado à finanças e com foco em Visão Computacional.",
        linkedin: "https://www.linkedin.com/in/andr%C3%A9-davys-carvalho-388732188/",
    },
    {
        image: andreDucca,
        title: "André Fernandes",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Formado em Ciências Econômicas e com mestrado em Informática pela PUC-Rio na área de Ciência de Dados. Possui experiência em Aprendizado de Máquina e Classificação de Séries Temporais.",
        linkedin: "https://www.linkedin.com/in/andré-ricardo-ducca-fernandes-4b20b810a/",
    },
    {
        image: assis,
        title: "Assis Felix",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Formado em Engenharia da Computação e com mestrado em andamento em Informática pela PUC-Rio. Possui experiência prática em desenvolvimento Front-end.",
        linkedin: "https://www.linkedin.com/in/assisfelix/",
    },
    {
        image: bruna,
        title: "Bruna Ferreira",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Formada em Ciência da Computação, com mestrado em Informática e com doutorado em andamento pela PUC-Rio na área de Interação Humano-Computador. Possui experiência prática em UX, Usabilidade e Requisitos.",
        linkedin: "https://www.linkedin.com/in/bruna-ferreira-41a6b429",
    },
    {
        image: cassia,
        title: "Cássia Novello",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Formada em Ciência da Computação e com mestrado em Engenharia de Sistemas e Computação na área de Inteligência Artificial. Possui ampla experiência prática, principalmente com soluções de Business Intelligence.",
        linkedin: "https://www.linkedin.com/in/cassianovello/",
    },
    {
        image: debora,
        title: "Debora Stuck",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Formada em Ciência da Computação, com mestrado em andamento em Informática pela PUC-Rio na área de Ciência de Dados e com MBA em Gerência de Projetos. Possui experiência prática como desenvolvedora front-end e back-end.",
        linkedin: "https://www.linkedin.com/in/deborastuck/"
    },
    {
        image: dieinison,
        title: "Dieinison Jack",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Formado em Ciência da Computação e com mestrado em Informática pela PUC-Rio na área de Visualização da Informação. Possui experiência prática com predição de séries temporais, Ciência de Dados, Aprendizado Profundo e design e desenvolvimento de interfaces centradas no usuário para a construção e análise de visualizações.",
        linkedin: "https://www.linkedin.com/in/dieinisonjack/"
    },
    {
        image: eduardo,
        title: "Eduardo Motta",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Formado em Ciência da Computação e com mestrado em andamento em Informática pela PUC-Rio na área de Engenharia de Software. Possui experiência com desenvolvimento de aplicações back-end e mobile, além de ter experiência com Amazon Web Services (AWS).",
        linkedin: "https://www.linkedin.com/in/eduardo-motta-57146b16a/"
    },
    {
        image: filipe,
        title: "Filipe Santiago",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Formado em Ciência da Computação e com mestrado em Computação na área de Engenharia de Sistemas e Informação. Possui experiência prática desenvolvendo soluções de Ciência de Dados e Aprendizado de Máquina.",
        linkedin:"https://www.linkedin.com/in/filipetsantiago",
    },
    {
        image: georges,
        title: "Georges Spyrides",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Candidato ao grau de Doutor pelo Departamento de Informática pela PUC-Rio. Tem grau de Mestre em Informática pela PUC-Rio e graduação em Engenharia de Produção. Sua pesquisa é principalmente nas áreas de Mineração de Processos e Otimização. Possui experiência em projetos de consultoria de gestão por processos aplicado a hospitais federais e gestão de risco e fraude no Ministério da Fazenda.",
        linkedin: "https://www.linkedin.com/in/georges-miranda-spyrides-160aba50/"
    },
    {
        image: hugo,
        title: "Hugo Villamizar",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Formado em Sistemas de Informação, com mestrado em Informática pela PUC-Rio na área Engenharia de Software e com doutorado em andamento na mesma área. Possui experiência prática em projetos de transferência de tecnologia e em projetos de pesquisa e desenvolvimento.",
        linkedin: "https://www.linkedin.com/in/hrguarinv/", 
    },
    {
        image: joao,
        title: "João Guilherme Mattos",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Formado em Engenharia Eletrônica e de Computação e com mestrado em andamento em Informática pela PUC-Rio na área de Aprendizado de Máquina. Possui experiência prática desenvolvendo sistemas e aplicando soluções de Business Intelligence em projetos de consultoria.",
        linkedin:"https://www.linkedin.com/in/jo%C3%A3o-guilherme-mattos-87636855/",
    },

    {
        image: kid,
        title: "Kid Valdez",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Formado em Engenharia de Sistemas, com mestrado em Computação na área de Aprendizado de Máquina e cursos de extensão na área de Mineração de Dados, Banco de Dados Distribuídos e Inteligência Artificial. Possui experiência aplicando soluções relacionadas em projetos de pesquisa e desenvolvimento.",
        linkedin: "https://www.linkedin.com/in/kyvaleriano",
    },
    {
        image: pedro,
        title: "Pedro Torres",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Formado em Ciência da Computação e com mestrado em Informática pela PUC-Rio na área de Visão Computacional. Possui experiência prática em projetos de Aprendizado de Máquina com foco em Visão Computacional.",
        linkedin: "https://www.linkedin.com/in/pedro-torres-5285751b2/"
    },
    {
        image: rafael,
        title: "Rafael França",
        subtitle:"Analista de Pesquisa e Desenvolvimento",
        description:"Técnico em Mecânica Industrial, graduado em Ciência da Computação pela PUC-Rio e com mestrado em andamento em Informática na área de Ciência de Dados. Com cursos de extensão nas áreas de Aprendizado de Máquina e Mineração de Processos. Possui experiência na área de projetos de pesquisa para a indústria e desenvolvimento de sistemas.",
        linkedin: "https://www.linkedin.com/in/rafaelagf/",
    },
];