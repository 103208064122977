import React from 'react'
import { CardMedia, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    card: {
        transition: "0.3s",
        height: "40vh",
        boxShadow: "0 2px 5px -1px #726d6d",
        textAlign: "center",
        padding: "2vh",
        '@media (max-width: 960px)': {
            margin:"5px",
            height: "50vh"
        },
        // cursor: "pointer",
        "&:hover": {
            transform: "translateY(-5px)",
            boxShadow: "10px 10px 10px 0 rgba(0,0,0,0.2)",
        },
    },
    media: {
      height: "8vh",
      marginTop: "5vh",
    },
    description: {
        marginTop: "2vh",
        color:"#495057",
        fontSize:"1.3em",
    },
    title:{
        // marginTop: "5vh",
        // marginBottom: "5vh",
        // fontWeight: "bold",
        // textAlign: "center",
        // fontSize: "1.2em",
        color: "#172541",
    }
  });

function VisionCard(props) {

    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardMedia style={{backgroundSize: "contain"}}
                className={classes.media}
                image={props.image}
                title={props.title}
            />
            <CardContent>
                <Typography className={classes.title} gutterBottom>
                    {props.title}
                </Typography>
                
                <Typography className={classes.description}>
                    {props.description}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default VisionCard;
