import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import imagePetrobras from '../../img/Petrobras.png';
import imageCenpes from '../../img/logo_cenpes.gif';


const useStyles = makeStyles(theme => ({
    partners: {
        width: '100%',
        backgroundColor: "#fff",
        paddingBottom: "2vh",
    },
    partnersContent: {
        maxWidth: "1200px",
        padding: "5vh",
        '@media (max-width: 768px)': {
            marginTop: "2vh",
            paddingBottom: "0vh"
        },
    },
    partnersTitle: {
        fontSize: "2.2em",
        fontWeight: "bold",
        '@media (max-width: 768px)': {
            textAlign: "left",
        }
    },
    partnersText: {
        fontSize: "1.3em",
        color: "#5F5F5F",
        textAlign: "left",
    },
    partnersLogos: {
        padding: "0vh",
        margin: "2vh",
    },
    partnersCenpes: {
        padding: "0vh",
    },
    partnersAmericanas: {
        'path': {
            fill: '#BB0F33',
        },
    }
}))

function Partners(props) {

    const classes = useStyles();

    return (
        <Grid container justify="center" className={classes.partners}>
            <Grid item className={classes.partnersContent} xs={9}>
                <div >
                    <Grid container>
                        <Grid item md={4} xs={12}>
                            <Typography className={classes.partnersTitle}>Parcerias</Typography>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Typography className={classes.partnersText}>
                                Os seguintes parceiros contam com a iniciativa ExACTa para a cocriação de soluções de transformação digital.
                                </Typography>
                        </Grid>
                        <Grid container item md={12} xs={12} spacing={6} justify={"center"} className={classes.partnersLogos}>
                            <Grid item>
                                <img src={imagePetrobras} height="50vh" alt="logo Petrobras"/>
                            </Grid>
                            <Grid item className={classes.partnersCenpes}>
                                <img src={imageCenpes} height="60vh" alt="logo Cenpes"/>
                            </Grid>
                            <Grid item className={classes.partnersAmericanas}>
                                <img src='/logo-americanas.svg' width="170vw" alt="logo Americanas"/>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

            </Grid>
        </Grid>
    );
}

export default Partners;
