import React from 'react'
import { Grid, GridList, GridListTile } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VisionCard from '../../molecule/cards/visionCard';
import * as vision from '../../utils/constants/vision';

const useStyles = makeStyles(theme => ({
    cards: {
        position: "relative",
        zIndex: "100",
        top: '-3vh',
        paddingLeft: "10vw",
        paddingRight: "10vw",
        paddingBottom: "5vh",
    },
    visionCards: {
        flex: 1,
    },
    rootGridListParent: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        paddingRight: '5vw',
    },
    gridTile: {
        height: "100vh",
        width: "100vw"
    }
}))

function Visions(props) {

    const classes = useStyles();

    return (
        <Grid container className={classes.cards} justify="center">
            <Grid container item md={9} xs={12} justify="center">
                <Grid container item spacing={4} justify="center">
                    <div className={classes.rootGridListParent}>
                        <GridList className={classes.gridList} container cols={1.1} cellHeight="auto">
                            {
                                vision.VISIONS.map(visions => (
                                    <GridListTile className={classes.gridTile}>
                                        <VisionCard
                                            image={window.location.origin + visions.image}
                                            title={visions.title}
                                            description={visions.description}
                                            className={classes.visionCards}
                                        />
                                    </GridListTile>
                                ))
                            }
                        </GridList>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Visions;
