import React from 'react';
import 'typeface-roboto';
import LandingPage from './pages/landingpage'
import './App.css';

function App() {
  return (
    <div className="App">
       <LandingPage />
    </div>
  );
}

export default App;
