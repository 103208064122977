import React from 'react'
import { Grid, ThemeProvider, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import imageBackground from '../../img/fundo_footer_2.png';
import imagePUC from '../../img/Selo PUC.svg';
import imageExacta from '../../img/Logo_Exacta_footer_correto.svg';
import imageDI from '../../img/logo_DI_footer.png';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import LocationOn from '@material-ui/icons/LocationOn';
import Email from '@material-ui/icons/Email';
import { Link as LinkScroll } from 'react-scroll';
import DoubleArrow from '../../img/Double_Arrows.svg';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles(theme => ({
    footer: {
        width: '100%',
        maxHeight: '85vh',
        backgroundImage: `url(${imageBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        color: "#fff",
        padding: '5vh',
    },
    footerContent: {
        maxWidth: "1200px",
        textAlign: "left",
        '@media (max-width: 768px)': {
            marginTop: "2vh",
        },
    },
    footerText: {
        fontSize: "1.2em",
        marginTop: "4%",
        textAlign: "left",
        display: 'block',
        cursor: 'pointer',
        lineHeight: "2.0em",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    footerTextLogo: {
        fontSize: "1.2em",
        paddingTop: "3vh",
        textAlign: "left",
        lineHeight: "1.9em",
    },
    footerTextCopyright: {
        fontSize: "1.2em",
        marginTop: "5vh",
        
        textAlign: "left",
        '@media (max-width: 768px)': {
            textAlign: "center",
            marginBottom: "5vh",
        },
    },
    footerTitle: {
        fontSize: "1.8em",
    },
    footerContacts: {
        fontSize: "1.2em",
        lineHeight: "1.9em",
        '@media (max-width: 768px)': {
            fontSize: "1.1em",
        },
    },
    footerLogos: {
        paddingBottom: "3vh",
        paddingTop: "3vh",
    },
    lineFooterLinks: {
        width: "72px",
        borderBottom: "2px solid #00A5DF",
        color: "#00A5DF",
    },
    lineFooterContacts: {
        width: "92px",
        borderBottom: "2px solid #00A5DF",
        color: "#00A5DF",
        marginBottom: "2%",
    },
    footerExacta: {
        '@media (min-width: 769px)': {
            justify: "flex-start",
        },
    },
    logoExactaFooter: {
        cursor: "pointer",
    },
    doubleArrow: {
        paddingRight: "5%",
    },
    linkContainer: {
         '@media (min-width: 769px)': {
            marginInline: "auto",
         }
    },
    iconContacts: {
        width: "100%", 
        '@media (min-width: 769px)': {
            width: "80%",
         }
    },
    iconContactsGrid: {
        paddingRight: "2%",
    }
}))

function Footer(props) {

    const classes = useStyles();

    return (
        <Grid container className={classes.footer} justify="center" >
            <Grid item container className={classes.footerContent} md={9} xs={12} justify="center">
                <Grid item container md={3} xs={12}  className={classes.footerExacta2}>
                    <Grid item container className={classes.footerExacta3} xs={12}>
                        <Grid item container className={classes.footerExacta}>
                            <LinkScroll
                                to="header"
                                spy={true}
                                smooth={true}
                                offset={-130}
                                duration={1000}
                            >
                                <img src={imageExacta} height="auto" width="95%" className={classes.logoExactaFooter} alt="logo ExACTa"/>
                            </LinkScroll>
                        </Grid>

                        <Typography align="center" className={classes.footerTextLogo} >
                            Experimentação Ágil. <br />
                            Cocriação. <br />
                            Transformação Digital.
                            </Typography>
                    </Grid>
                    <Grid item container className={classes.footerLogos} alignItems="flex-end" justify="space-between" xs={10} >
                        <Grid item className={classes.footerDI}>
                            <Link href="http://www.inf.puc-rio.br/" target="_blank">
                                <img src={imageDI} alt="Logo Departamento de Informática" height="auto" width="90%" />
                            </Link>
                        </Grid>
                        <Grid item className={classes.footerPuc}>
                            <Link href="http://www.puc-rio.br/index.html" target="_blank" >
                                <img src={imagePUC} alt="Logo PUC-Rio" height="auto" width="80%" />
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container display="flex" md={9} xs={12}>
                    <Grid item container md={6} xs={5} >
                        <Grid item md={3} xs={8} className={classes.linkContainer}>
                            <Typography className={classes.footerTitle}>Links</Typography>
                            <Grid>
                                <div className={classes.lineFooterLinks}></div>
                            </Grid>

                            <ThemeProvider theme={theme} >
                                <LinkScroll
                                    activeClass="active"
                                    to="header"
                                    spy={true}
                                    smooth={true}
                                    offset={-130}
                                    duration={600}
                                    className={classes.footerText}
                                >
                                    <img src={DoubleArrow} className={classes.doubleArrow} alt="ícone duas setas"/>
                                    Inicial</LinkScroll>
                                <LinkScroll activeClass="active"
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    offset={-90}
                                    duration={600}
                                    className={classes.footerText}
                                >
                                    <img src={DoubleArrow} className={classes.doubleArrow} alt="ícone duas setas"/>
                                    Sobre</LinkScroll>
                                <LinkScroll activeClass="active"
                                    to="lineOfAction"
                                    spy={true}
                                    smooth={true}
                                    offset={-90}
                                    duration={600}
                                    className={classes.footerText}
                                >
                                    <img src={DoubleArrow} className={classes.doubleArrow} alt="ícone duas setas"/>
                                    Atuação</LinkScroll>
                                <LinkScroll activeClass="active"
                                    to="equipe"
                                    spy={true}
                                    smooth={true}
                                    offset={-90}
                                    duration={600}
                                    className={classes.footerText}
                                >
                                    <img src={DoubleArrow} className={classes.doubleArrow} alt="ícone duas setas"/>
                                    Equipe</LinkScroll>
                                <LinkScroll activeClass="active"
                                    to="publication"
                                    spy={true}
                                    smooth={true}
                                    offset={-90}
                                    duration={600}
                                    className={classes.footerText}
                                >
                                    <img src={DoubleArrow} className={classes.doubleArrow} alt="ícone duas setas"/>
                                    Publicações</LinkScroll>
                                <LinkScroll activeClass="active"
                                    to="address"
                                    spy={true}
                                    smooth={true}
                                    offset={-90}
                                    duration={600}
                                    className={classes.footerText}
                                >
                                    <img src={DoubleArrow} className={classes.doubleArrow} alt="ícone duas setas"/>
                                    Mapa</LinkScroll>
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                    <Grid item container md={6} xs={7}>
                        <Grid item>
                            <Typography className={classes.footerTitle}>Contatos</Typography>
                            <Grid container justify="flex-start">
                                <div className={classes.lineFooterContacts}></div>
                            </Grid>
                            <Grid item container display="flex">
                                <Grid item md={1} xs={1} className={classes.iconContactsGrid}>
                                    <LocationOn className={classes.iconContacts}/>
                                </Grid>
                                <Grid item md={5} xs={11} >
                                    <Typography className={classes.footerContacts} >
                                        Rua Marquês de São Vicente, 225,
                                        Gávea - Rio de Janeiro, RJ Brasil.
                                        CEP 22451-900 <br /> <br />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container>
                                <Grid item md={1} xs={1} className={classes.iconContactsGrid}>
                                    <Email  className={classes.iconContacts}/>
                                </Grid>
                                <Grid item md={6} xs={11}>
                                    <Typography className={classes.footerContacts} >
                                        <Link href="mailto: contato@exacta.inf.puc-rio.br" color="inherit">contato@exacta.inf.puc-rio.br</Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container md={12} xs={12} justify="center" >
                    <Grid item>
                        <Typography className={classes.footerTextCopyright}>Copyright © Exacta. 2019. PUC-Rio. Todos os direitos reservados.</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Footer;
