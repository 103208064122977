import React from "react";
import { Link } from '@material-ui/core';
import "./index.css";

import TeamCard from '../../molecule/cards/teamCard'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";


export default function SlideTeam(props) {

  const renderSlides = () =>
    props.time.map((dev, index) => (
        <Link href={dev.linkedin} key={index} target="_blank">
          <TeamCard
                image={window.location.origin + dev.image}
                title={dev.title}
                subtitle={dev.subtitle}
                description={dev.description}
                linkedin={dev.linkedin}
            />
          </Link>
    ));


    var settings = {
      dots:false,
      slidesToShow:4,
      slidesToScroll:4,
      arrows:true,
      infinite:false,
      initialSlide: 0,
      rows: 1,
      
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            
          }
        },
      ]
      
    }

  return (
    <div >
      <Slider  {...settings} 
      >
        {renderSlides()}
      </Slider>
    </div>
  );
}