import React from "react";
import { Link } from '@material-ui/core';
import './index.css';

import PaperCard from '../../molecule/cards/paperCard'
import * as paper from '../../utils/constants/publication';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

export default function SlidePublication() {


  const renderSlides = () =>
  paper.PAPERS.map( (papers, index) => (
      <div key={index}>
        <Link href={papers.link} target="_blank">
          <PaperCard
            image={window.location.origin + papers.image}
            title={papers.title}
            authors={papers.authors}
            date={papers.date}
          />
        </Link>

      </div>
    ));

    var settings = {
      dots:false,
      slidesToShow:4,
      slidesToScroll:4,
      arrows:true,
      infinite:false,
      initialSlide: 0,
      rows: 1,
      
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            
          }
        },
      ]
      
    }

  return (
    <div className="App">
      <Slider  {...settings} 
      >
        {renderSlides()}
      </Slider>
    </div>
  );
}