import React from 'react'
import { Grid, Typography, ThemeProvider } from '@material-ui/core';
import { makeStyles, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import imageBackground from '../../img/fundo_linhas_atuacao_3.png';


const useStyles = makeStyles(theme => ({
    lineOfAction: {
        '@media (max-width: 768px)': {
            height: '45vh',
        },
        '@media (min-width: 769px)': {
            height: '55vh',
        },
        backgroundImage: `url(${imageBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: "#fff",
        color: "#fff",
    },
    lineOfActionContent: {
        maxWidth: "1200px",
        paddingLeft: "10%",
        paddingRight:"10%",
        paddingTop:"5vh",
    },
    lineOfActionText:{
        textAlign:"center" ,
        fontSize:"2.6em" ,
        marginTop: "2%",
        '@media (max-width: 768px)': {
            fontSize: "1.5em",
            marginTop: "5vh",
        }
    },
    lineOfActionTitle:{
        textAlign:"center",
        fontSize:"2.6em",
        fontWeight: "bold",
        '@media (max-width: 768px)': {
            fontSize: "2.0em",
            fontWeight: "bold",
        }
    }
}))

function LineOfAction(props) {
    let theme = createMuiTheme();
    theme = responsiveFontSizes(theme);

    const classes = useStyles();

    return (

        <Grid container justify="center" alignItems="center" id="lineOfAction" className={classes.lineOfAction}>
            <Grid item className={classes.lineOfActionContent} md={9}>
                <ThemeProvider theme={theme}>
                        <Grid item md={12} xs={12}>
                            <Typography className={classes.lineOfActionTitle} >Áreas de Atuação e Tecnologias</Typography>
                        </Grid>
                        <Grid item md={12} xs={12} >
                            <Typography className={classes.lineOfActionText} >
                                Pesquisa Aplicada, Engenharia de Software, Multidisciplinaridade, User Experience (UX),
                                Inteligência Artificial, IoT, Machine Learning, Data Science, 
                                Visualização de Dados, Otimização
                            </Typography>
                        </Grid>
                    </ThemeProvider>
            </Grid>
        </Grid>
    );
}

export default LineOfAction;
