import paper1_vehicle_routing from '../../../img/publications/artigo1_vehicle_routing.png';
import paper2_gestures from '../../../img/publications/artigo2_gestures.png';
import paper3_security_related from '../../../img/publications/artigo3_security_related.png';
import paper4_visual_exploration_ensemble from '../../../img/publications/artigo4_visual_exploration_ensemble.png';
import paper5_tensorpose from '../../../img/publications/artigo5_tensorpose.png';
import paper6_lean_rd from '../../../img/publications/artigo6_lean_rd.png';
import paper7_equipment_detection from '../../../img/publications/artigo7_equipment_detection.png';
import paper8_lessons_learned from '../../../img/publications/artigo8_lessons_learned.png';

// ordem cronológica
export const PAPERS = [
    {
        image: paper8_lessons_learned,
        title:"Lessons Learned from a Lean R&D Project",
        // authors:"Bianca Teixeira; Bruna Ferreira; André Damasceno; Simone D.J. Barbosa; Cassia Novello; Hugo Villamizar; Marcos Kalinowski; Thuener Silva; Jacques Chueke; Hélio Lopes; André Kuramoto; Bruno Itagyba; Cristiane Salgado; Sidney Comandulli; Marinho Fischer; Leonardo Fialho",
        authors:"Bianca Teixeira, Bruna Ferreira, André Damasceno, Simone D.J. Barbosa, Cassia Novello, Hugo Villamizar, Marcos Kalinowski, Thuener Silva, Jacques Chueke, Hélio Lopes, André Kuramoto, Bruno Itagyba et al",
        date:"April 2021",
        link:"https://www.insticc.org/node/TechnicalProgram/iceis/2021/presentationDetails/104598",
    },
    {
        image: paper7_equipment_detection,
        title:"A Robust Real-time Component for Personal Protective Equipment Detection in an Industrial Setting",
        // authors:"Pedro Torres; André Davys; Thuener Silva; Luiz Schirmer; André Kuramoto; Bruno Itagyba; Cristiane Salgado; Sidney Comandulli; Patricia Ventura; Leonardo Fialho; Marinho Fischer; Marcos Kalinowski; Simone D.J. Barbosa; Hélio Lopes",
        authors:"Pedro Torres, André Davys, Thuener Silva, Luiz Schirmer, André Kuramoto, Bruno Itagyba, Cristiane Salgado, Sidney Comandulli et al",
        date:"April 2021",
        link:"https://www.insticc.org/node/TechnicalProgram/iceis/2021/presentationDetails/104526",
    },
    {
        image: paper6_lean_rd,
        title:"Lean R&D: An Agile Research and Development Approach for Digital Transformation",
        // authors:"Marcos Kalinowski; Hélio Lopes; Simone D.J. Barbosa; Marcus Poggi; Thuener Silva; Hugo Villamizar; Jacques Chueke; Bianca Teixeira; Juliana Pereira; Bruna Ferreira; Rodrigo Lima; Gabriel Cardoso; Alex Teixeira; Jorge Warrak; Marinho Fischer; André Kuramoto; Bruno Itagyba; Cristiane Salgado; Carlos Pelizaro; Deborah Lemes; Marcelo Costa; Marcus Waltemberg; Odnei Lopes",
        authors:"Marcos Kalinowski, Solon Batista, Hélio Lopes, Simone D.J. Barbosa, Marcus Poggi, Thuener Silva, Hugo Villamizar, Jacques Chueke et al",
        date:"November 2020",
        link:"https://ieeexplore.ieee.org/abstract/document/9226317",
    },
    {
        image: paper5_tensorpose,
        title:"Tensorpose: Real-time pose estimation for interactive applications",
        authors:"Luiz José Schirmer Silva, Djalma Lúcio Soares da Silva, Alberto Barbosa Raposo, Luiz Velho, Hélio Lopes",
        date:"December 2019",
        link:"http://bit.ly/2OPM1O7",
    },
    {
        image: paper4_visual_exploration_ensemble,
        title:"Visual exploration of an ensemble of classifiers",
        authors:"Paula Ceccon Ribeiro, Guilherme G. Schardong, Simone D.J. Barbosa, Clarisse Sieckenius de Souza, Hélio Lopes",
        date:"December 2019",
        link:"http://bit.ly/2rSeycE"
    },
    {
        image: paper3_security_related,
        title:"An Approach for Reviewing Security-Related Aspects in Agile Requirements Specifications of Web Applications",
        authors:"Hugo Villamizar, Amadeu Anderlin Neto, Marcos Kalinowski, Alessandro Garcia, Daniel Méndez",
        date:"September 2019",
        link:"https://ieeexplore.ieee.org/document/8920644"
    },
    {
        image: paper2_gestures,
        title:"Self-Previewing Gestures and the Gesture-and-Effect Model",
        authors:"Jacques Chueke, George Buchanan, Stephanie Wilson, Luís Anunciação",
        date:"July 2017",
        link:"https://dl.acm.org/doi/10.14236/ewic/HCI2017.34"
    },
    {
        image: paper1_vehicle_routing,
        title:"Improved branch-cut-and-price for capacitated vehicle routing",
        authors:"Diego Pecin, Artur Pessoa, Marcus Poggi, Eduardo Uchoa",
        date:"March 2017",
        link:"https://link.springer.com/article/10.1007%2Fs12532-016-0108-8"
    },
];

