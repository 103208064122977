import React from 'react'
import { Grid, Typography, IconButton, Collapse, Card, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import imageFotoMobile from '../../img/sobre2.png';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


const useStyles = makeStyles(theme => ({
    aboutContent: {
        justify: "center",
        paddingLeft: "5vw",
        paddingRight: "5vw",
    },
    aboutContentImage: {
            justify: "center",
    },
    paperAbout: {
        padding: "5vh",
        textAlign: "left",
        marginTop: "-5vh",
        boxShadow: "5px 5px 15px -1px #726d6d",
    },
    imageAboutGrid: {
        marginTop: "2vh",   
    },
    imageAbout: {
        width: "100vw",
    },
    textSubtitle: {
        textAlign: "center",
        fontSize: "1.5em",
        color: "#4b5981",
        letterSpacing: "0.5px",
        lineHeight: "18px",
        marginTop: "10px",
        marginBottom: "15px",
        fontWeight: "bold",
    },
    textAbout: {
        marginTop: "15px",
        paddingTop: "0px",
        color: "#495057",
        fontSize: "1.3em",
    },
    lineAbout: {
        width: "100%",
        borderBottom: "1px solid #1a0c58",
        color: "#333",

    },
    aboutTitle: {
        textAlign: "center",
        fontSize: "2.0em",
        fontWeight: "bold",
    },

    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        display: "none",
    },
    expandClose:{
        visibility: "visible",
    },
    buttonAbout:{
        textTransform: 'none',
        color: "#495057", 
    }
    
}))

function About(props) {

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Grid container className={classes.about} id="about" justify="center" >
            <Grid item container className={classes.aboutContentImage} md={9} xs={12} >
                    <Grid container item >
                        <Grid item className={classes.imageAboutGrid}>
                            <img src={imageFotoMobile} className={classes.imageAbout} alt="imagem escritório ExACTa"/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container className={classes.aboutContent} md={9} xs={12} >
                <Card className={classes.paperAbout}>
                    <Grid item className={classes.aboutPaperGrid} md={9} xs={12}>
                        <Typography className={classes.aboutTitle}>
                            Sobre a ExACTa
                        </Typography >
                        <Typography className={classes.textSubtitle} >
                            Experimentação Ágil. Cocriação. Transformação Digital.
                        </Typography>
                        <div className={classes.lineAbout}></div>
                        <Typography className={classes.textAbout}>
                            ExACTa é uma iniciativa da PUC-Rio de experimentação ágil e cocriação para transformação digital. A iniciativa foi criada por quatro professores do quadro principal do Departamento de Informática, pesquisadores das áreas de Ciência de Dados, Engenharia de Software, Otimização e Interação Humano Computador. A visão da iniciativa é estreitar os laços entre pesquisa e desenvolvimento, permitindo aplicar resultados de pesquisa para desencadear inovação e excelência operacional nas empresas parceiras.
                        </Typography>
                    </Grid>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                        <Button size="small" className={classes.buttonAbout}>Ver Mais</Button>
                    </IconButton>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Typography className={classes.textAbout}>
                            Entre os diferenciais da iniciativa estão a forte integração com pesquisa de ponta sendo desenvolvida no Departamento de Informática da PUC-Rio (conceito CAPES máximo em pesquisa na área de computação, 7), o processo próprio e inovador para a cocriação de soluções de pesquisa e desenvolvimento com agilidade, a equipe altamente qualificada e o ambiente especialmente projetado para este fim. A iniciativa ExACTa permite aplicar na prática resultados de pesquisa gerados tanto no contexto da iniciativa quanto em laboratórios temáticos do Departamento de Informática, como o DASLAB, GALGOS, IDEIAS e Software Science Lab. Com estes diferenciais, ExACTa configura a iniciativa ideal para a transformação digital das empresas parceiras.
                        </Typography>
                        <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandClose]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show less"
                    >
                        <ExpandLessIcon />
                        <Button size="small"  className={classes.buttonAbout}>Ver Menos</Button>
                    </IconButton>
                    </Collapse>
                </Card>
            </Grid>
        </Grid>
    );
}

export default About;
