import React from 'react'
import { CardMedia, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    card:{
         marginTop:"5%",
         width: "initial",  
         '@media (max-width: 599px)': {
            height: "65vh",
            marginRight: "3vw",
            //width: "95%",
        },
        '@media (min-width: 960px)': {
            height: "400px",
        },
        '@media (min-width: 1201px)': {
            height: "400px",
        },
    },
    media: {
        '@media (max-width: 599px)': {
            height: "60%",
        },
        '@media (min-width: 960px)': {
            height: "45%",
        },
        '@media (min-width: 1201px)': {
            height: "60%",
        },
        backgroundSize: "cover", 
        transition: "transform .2s",
         "&:hover": {
            transform: "scale(1.1)",

         },
    },
    titlePublication: {
        color:"#333457", 
        fontSize:"1.2em", 
        textAlign:"left", 
        fontWeight:"bold",
    },
    datePublication:{
        fontSize: "1.0em",
        color: "#687076",
        textAlign: "left",
    },
    authorsPublication:{
        fontSize: "1.1em",
        textAlign: "left",
    },

  });

function PaperCard(props) {

    const classes = useStyles();

    return (
        
        <Card className={classes.card} >   
                <CardMedia 
                    className={classes.media}
                    image={props.image}
                    title={props.title}
                    link={props.link} 
                />
            <CardContent>
                <Typography gutterBottom className={classes.titlePublication} >
                    {props.title}
                </Typography>
                <Typography gutterBottom className={classes.authorsPublication} >
                    {props.authors}
                </Typography>
                <Typography gutterBottom className={classes.datePublication} md={12} xs={12}>
                    {props.date}
                </Typography>
            </CardContent>
        </Card>
        
    );
}

export default PaperCard;
