import React from 'react'
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableTextMobileStepper from '../../organism/carousel/index';
import SwipeableTextMobileStepperMobile from '../../organism/carousel/indexMobile';

const useStyles = makeStyles(theme => ({

}))

function Carousel(props) {

    const classes = useStyles();

    return (
        <Grid className={classes.carousel} id="header">
            <Grid container className={classes.headerCarousel}>
                <Hidden xsDown>
                    <SwipeableTextMobileStepper>

                    </SwipeableTextMobileStepper>
                </Hidden>
                <Hidden smUp>
                    <SwipeableTextMobileStepperMobile>

                    </SwipeableTextMobileStepperMobile>
                </Hidden>
            </Grid>
        </Grid>
    );
}

export default Carousel;