import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, ThemeProvider, Link, Typography, Button } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import imageBanner1 from '../../img/banner_mobile_01_.png';
import imageBanner2 from '../../img/banner_mobile_02.png';
import imageBanner3 from '../../img/banner_mobile_03.png';
import imageBanner4 from '../../img/banner_mobile_04.png';
import imageBanner5 from '../../img/banner_mobile_05.png';
import imageBanner6 from '../../img/banner_mobile_06.png';
import imageBanner7 from '../../img/banner_mobile_07.png';
import imageBanner8 from '../../img/banner_mobile_08.png';
import imageWatermarkMobile from '../../img/vetor_mobile_darker (1).svg';
import Email from '@material-ui/icons/Email';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5vh",
    },
    img: {
        display: 'block',
        overflow: 'hidden',
        width: '100%',
        height: "100%",
    },
    stepper: {
        position: "relative",
        top: "-12vh",
        backgroundColor: "transparent",
    },
    buttonsContainer: {
        position: "absolute",
        width: "100%",
    },
    buttons: {
        display: "flex",
        justifyContent: "space-between",
    },
    button: {
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        color: "white",
        margin: "0 8px"
    },
    carouselTextContainer: {
        position: "absolute",
        maxWidth: "1200px",
        color: "#fff",
        padding: "5%",
    },
    carouselText: {
        fontSize: "1.3em",
        fontWeight: "bold",
        marginTop: "2vh",
        textShadow: "2px 2px 3px #000",
        letterSpacing: "0.5px",
        display: "flex",
        textAlign: "left",
    },
    carouselTextTitle: {
        fontSize: "2.2em",
        fontWeight: "bold",
        textShadow: "2px 2px 3px #000",
        letterSpacing: "0.5px",
        textAlign: "left",
        display: "flex",
        marginTop: "2vh"
    },
    carouselButton: {
        justifyContent: "center",
    },
    buttonCarouselContate: {
        marginTop: "5vh",
        border: "solid 1px #FFF",
        backgroundColor: "#010B4A8C",
        borderRadius: "6px",
        textTransform: 'none',
        color: "#FFF",
        zIndex: "30",
        '&:hover': {
            backgroundColor: '#261570',
        },
    },
    buttonIcon: {
        verticalAlign: "middle",
    },
    divWatermark: {
        backgroundColor: "transparent",
        width: '100%',
        position: "absolute",
    },
    carouselMobile: {
        '@media (max-width: 768px)': {
            width: '100vw',
        }
    }
}));



function SwipeableTextMobileStepperMobile() {
    const classes = useStyles();
    const theme = useTheme();

    const tutorialSteps = [
        {
            imgPath: () => (<img className={classes.img}
                src={imageBanner1}
                alt="ExACTa" />),
        },
        {
            imgPath: () => (<img className={classes.img}
                src={imageBanner2}
                alt="ExACTa" />),
        },
        {
            imgPath: () => (<img className={classes.img}
                src={imageBanner3}
                alt="ExACTa" />),
        },
        {
            imgPath: () => (<img className={classes.img}
                src={imageBanner4}
                alt="ExACTa" />),
        },
        {
            imgPath: () => (<img className={classes.img}
                src={imageBanner5}
                alt="ExACTa" />),
        },
        {
            imgPath: () => (<img className={classes.img}
                src={imageBanner6}
                alt="ExACTa" />),
        },
        {
            imgPath: () => (<img className={classes.img}
                src={imageBanner7}
                alt="ExACTa" />),
        },
        {
            imgPath: () => (<img className={classes.img}
                src={imageBanner8}
                alt="ExACTa" />),
        }
    ];

    const [activeStep, setActiveStep] = React.useState(0);

    const handleStepChange = step => {
        setActiveStep(step);
    };

    return (
        <div className={classes.root} >
            <ThemeProvider theme={theme}>
                    <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        interval={10000}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                        style={{ overflow: 'hidden' }}
                    >
                        {tutorialSteps.map((step, index) => (
                            <div key={step.label}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <step.imgPath></step.imgPath>
                                ) : null}
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                    <Grid className={classes.divWatermark}>
                        <img src={imageWatermarkMobile} width="100%" height="100%" alt="marca d'água"/>
                    </Grid>
                <Grid container className={classes.carouselTextContainer} >
                    <Grid item md={9} xs={12}>
                        <Typography className={classes.carouselTextTitle} >
                            Pesquisa e <br /> Desenvolvimento Ágil <br /> para Transformação Digital
                        </Typography>
                        <Typography className={classes.carouselText} >

                            Iniciativa da PUC-Rio. Líder em Integração com a Indústria na América Latina.
                        <br />
                            Vinculada ao Departamento de Informática. <br /> Excelência em Pesquisa - CAPES 7.

                        </Typography >
                    </Grid>
                    <Grid item container className={classes.carouselButton}>
                        <Link href="mailto: contato@exacta.inf.puc-rio.br" color="inherit">
                            <Button variant="contained" className={classes.buttonCarouselContate} endIcon={<Email fontSize="small" />}>
                                Contate-nos
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </div>
    );
}

export default SwipeableTextMobileStepperMobile;