import imageAgile from '../../../img/1_ico_Agile_2.svg';
import imageCocriacao from '../../../img/ico_cocriacao_2.svg';
import imageTransfDigital from '../../../img/ico_transf digial_2.svg';

export const VISIONS = [
    {
        image: imageAgile,
        title:"Experimentação Ágil",
        description:"Processo focado na integração com a estratégia do negócio e pautado em conceitos de agilidade e experimentação contínua."
    },
    {
        image: imageCocriacao,
        title:"Cocriação",
       description:"Atuação conjunta com a equipe cliente, promovendo sinergia para a criação ágil de soluções que agreguem valor ao negócio."
    },
    {
        image: imageTransfDigital,
        title:"Transformação Digital",
        description:"Soluções inovadoras e disruptivas empregando tecnologia de ponta para promover excelência operacional."
    },
];

