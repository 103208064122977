import React, { useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Grid, Hidden } from '@material-ui/core';
import Header from '../template/landingpage/header'
import Visions from '../template/landingpage/visions'
import VisionsMobile from '../template/landingpage/visionsMobile'
import About from '../template/landingpage/about'
import LineOfAction from '../template/landingpage/lineOfAction'
import Team from '../template/landingpage/team'
import TeamMobile from '../template/landingpage/teamMobile'
import Publications from '../template/landingpage/publications'
import PublicationsMobile from '../template/landingpage/publicationsMobile'
import AboutMobile from '../template/landingpage/aboutMobile'
import Partners from '../template/landingpage/partners'
import Footer from '../template/landingpage/footer'
import WorkWithUs from '../template/landingpage/workWithUs'
import Address from '../template/landingpage/address'
import Carousel from '../template/landingpage/carousel'
import { makeStyles } from '@material-ui/core/styles';
import ScrollButtonTop from './scrollButtonTop.js';


const useStyles = makeStyles(theme => ({
    root: {
        overflow: "hidden",
    }
}))

function LandingPage(props) {

    const classes = useStyles();
    const [work, setWork] = useState("False");


    fetch("workWithUsFlag.json")
        .then(res => res.json())
        .then(data => setWork(data["Disabled"]));


    return (
        <Grid container justify="center">
            <Grid item className={classes.root}>
                <Router>
                    <Header />
                    <Carousel />
                    <Hidden xsDown>
                        <Visions />
                        <About />
                    </Hidden>
                    <Hidden smUp>
                        <VisionsMobile />
                        <AboutMobile />
                    </Hidden>
                    <LineOfAction />
                    <Hidden xsDown>
                        <Team />
                        <Publications />
                    </Hidden>
                    <Hidden smUp>
                        <TeamMobile />
                        <PublicationsMobile />
                    </Hidden>
                    <Partners />
                    {work === "False" ?
                        <WorkWithUs />
                        : ""}
                    <Address />
                    <ScrollButtonTop />
                    <Footer />
                </Router>
            </Grid>
        </Grid>
    );
}

export default LandingPage;