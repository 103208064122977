import React from 'react'
import { Grid, CardMedia, Button, Card, CardContent, Typography, CardActions, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OpenInNew } from '@material-ui/icons';


const useStyles = makeStyles({
    card: {
        '@media (max-width: 599px)': {
            marginRight: "3vw",
        },
    },
    content: {
        height: "auto",
        overflowY: "hidden",
    },
    description: {
        fontSize: "1.2em",
        textAlign: "left",
        color: "#4B5981",
    },
    title: {
        fontWeight: "bold",
        fontSize: "1.4em",
        color: "#23313C",
        textAlign: "left",
        '@media (min-width: 960px)': {
            fontSize: "1.2em",
        },
    },
    subtitle: {
        color: "#0C23AC",
        fontSize: "1.3em",
        textAlign: "left",
        lineHeight: "18px",
        fontWeight: "bold"
    },
    teamDetails: {
        borderTop: "2px solid #EAEAEA",
        backgroundColor: "#FFFFFF",
    },
    cardAction: {
        padding: "0px",
        marginTop: "2vh",
    }

});



function TeamCard(props) {

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = (expanded) => {
        setExpanded(expanded);
    };




    return (
        <Card className={classes.card}>
            <CardMedia
                component="img"
                className={classes.media}
                image={props.image}
                title={props.title}
            />
            <CardContent className={classes.content} onMouseEnter={() => handleExpandClick(true)} onMouseLeave={() => handleExpandClick(false)} >
                <Typography className={classes.title}>
                    {props.title}
                </Typography>
                <Typography gutterBottom className={classes.subtitle}>
                    {props.subtitle}
                </Typography>
                <Collapse in={expanded} timeout="auto" collapsedHeight={63}>
                    <Typography className={classes.description} gutterBottom >
                        {props.description}
                    </Typography>
                </Collapse>
            </CardContent>
            <CardActions className={classes.cardAction}>
                <Grid container className={classes.teamDetails} >
                    <Button size="small" color="primary" fullWidth endIcon={<OpenInNew />}>
                        Detalhes
                    </Button>
                </Grid>
            </CardActions>
        </Card>
    );
}

export default TeamCard;
