import React from 'react'
import { Grid, Typography, Box, Link, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import imageBackground from '../../img/foto_trabalhe.png';
import bannerBackground from '../../img/bg_trabalhe_conosco.png';
import Email from '@material-ui/icons/Email';


const useStyles = makeStyles ({
    workWithUs: {
        height: '55vh',
        backgroundImage: `url(${imageBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        marginBottom: '320px',
        backgroundPosition: 'top center'
    },
    workWithUsContent: {
        maxWidth: "1200px",
    },
    banner: {
        backgroundImage: `url(${bannerBackground})`,
        backgroundRepeat: 'no-repeat',
        padding: '30px',
        position: 'relative',
        top: '320px',
        backgroundColor: '#fbbb21',
        '@media (min-width: 769px)': {
            borderRadius: "50px",
            backgroundSize: '350px',
            backgroundPosition: 'top right',
        },
        '@media (max-width: 768px)': {
            backgroundPosition: 'top right',
        }
    },

    title: {
        color: "#000",
        fontWeight: 'bold',
        textAlign: 'left',
        '@media (max-width: 768px)': {
            fontSize: "2.2em",
        },
        '@media (min-width: 769px)': {
            fontSize: "4.0em",
        },
    },
    subtitle: {
        color: "#000",
        fontSize: "3.6em",
        textAlign: 'left',
        '@media (max-width: 768px)': {
            fontSize: "1.6em",
        },
        '@media (min-width: 769px)': {
            fontSize: "2.4em",
        },
    },
    text: {
        color: "#000",
        textAlign: 'left',
        marginTop: '1vh',
        '@media (max-width: 768px)': {
            fontSize: "1.2em",
        },
        '@media (min-width: 769px)': {
            fontSize: "1.4em",
        },
    },
    workButtonContact: {
        border: "solid 1px #FFF",
        backgroundColor: "#010B4A8C",
        borderRadius: "6px",
        textTransform: 'none',
        color: "#FFF",
        '&:hover': {
            backgroundColor: '#0315867E',
        },
        zIndex: "30",
        marginTop: "2vh",
    },
    boxBold: {
        fontWeight: 'bold',
        display: 'inline',
    },
    boxNowRap: {
        display: 'inline',
        whiteSpace: 'nowrap',
    }
})

function WorkWithUs(props) {

    const classes = useStyles();

    return (

        <Grid container justify="center" alignItems="center" id="work" className={classes.workWithUs}>
            <Grid item className={classes.workWithUsContent} md={6}>
                <Grid container item className={classes.banner}>
                    <Grid item>
                        <Typography className={classes.title}>
                            Trabalhe Conosco
                        </Typography>
                        <Typography className={classes.subtitle}>
                            Faça parte do time ExACTa PUC-Rio!
                        </Typography>
                        <Typography className={classes.text}>
                            É comum que tenhamos demandas para a contratação de agentes de transformação digital que queiram nos ajudar a alavancar os resultados das nossas empresas parceiras através de soluções inovadoras.
                            Temos contratado profissionais nas áreas de <Box className={classes.boxBold}>Ciência de Dados, Design de <Box className={classes.boxNowRap}>UX/UI</Box>, Desenvolvimento <Box className={classes.boxNowRap}>Front-end/Back-end</Box>, Gestão Ágil de Projetos (Scrum Master), Gestão Ágil de Produtos (Product Owner) e <Box className={classes.boxNowRap}></Box>Infraestrutura/DevOps</Box>.
                            Envie o seu currículo para <Box className={classes.boxNowRap}>contato@exacta.inf.puc-rio.br</Box> e venha trabalhar com a gente!
                        </Typography>
                    </Grid>
                    <Grid item container className={classes.workButton} justify={'center'}>
                        <Link href="mailto: contato@exacta.inf.puc-rio.br" color="inherit" underline="none">
                            <Button variant="contained" className={classes.workButtonContact} endIcon={<Email fontSize="small" />}>
                                Contate-nos
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default WorkWithUs;
