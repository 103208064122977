import React, { useState } from 'react';
import { Grid, Typography, Link, ThemeProvider, Hidden, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import imageExacta from '../../img/Logo_Exacta_Header.svg';
import imagePUC from '../../img/Selo PUC.svg';
import imageDI from '../../img/logo_DI_topo.png';
import Menu2 from '../../organism/menu/menu';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import LocationOn from '@material-ui/icons/LocationOn';
import Email from '@material-ui/icons/Email';
import { Link as LinkScroll } from 'react-scroll';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles(theme => ({
    header: {
        width: "100%",
        backgroundColor: "#fff",
        flex: "in-line",
        color: "#000",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "1000",
    },
    headerContent: {
        width: "1200px",
    },
    headerTop: {
        flexFlow: "row",
        backgroundColor: "#fff",
    },
    headerMenu: {
        '@media (max-width: 768px)': {
            padding: "1vh",
        },
        backgroundColor: "#fff",
        margin: "auto"
    },
    headerTopText: {
        fontSize: "1.0em",
        marginLeft: "3px",
        cursor: "pointer",
        "&:hover": {
            color: '#0082f3',
        },

    },
    topIcons: {
        flexFlow: "row",
    },
    logosHeader: {
        zIndex: "1500",
    },
    logoExacta: {
        '@media (min-width: 769px)': {
            width: "80%",
        },
        '@media (max-width: 768px)': {
            width: "70%",
        },
        height: "auto",
        cursor: "pointer",
    },

    headerContainerTopScroll: {
        display: "none",
    },
    headerScroll: {
        width: "100%",
        backgroundColor: "#fff",
        flex: "in-line",
        color: "#000",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "1000",
        '@media (min-width: 769px)': {
            height: "8vh",
        },
        transition: "all 0.5s ease",
    },
    logoExactaScroll: {
        '@media (min-width: 769px)': {
            width: "50%",
        },
        '@media (max-width: 768px)': {
            width: "70%",
        },
        height: "auto",
        cursor: "pointer",
        transition: "all 0.5s ease"
    },
}))




function Header(props) {

    const classes = useStyles();

    const [headerScroll, setHeaderScroll] = useState(false);
    window.onscroll = function () { scrollFunction() };

    function scrollFunction() {

        if (document.body.scrollTop > 70 || document.documentElement.scrollTop > 70) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    }

    return (

        <Grid container justify="center" className={headerScroll ? classes.headerScroll : classes.header}>

            <Grid container item className={classes.headerContent} md={12}>
                <Hidden xsDown>
                    <Slide direction="down" in={!headerScroll}>
                        <Grid container item className={headerScroll ? classes.headerContainerTopScroll : classes.headerContainerTop} justify="space-around" >
                            <Grid container item md={10} className={classes.headerContainerTop} justify="space-around" >
                                <Grid container item className={classes.headerTop} alignItems="center" md={5}>
                                    <Grid item container className={classes.topIcons} md={5}>
                                        <Email fontSize="small" />
                                        <Typography className={classes.headerTopText}>
                                            <Link href="mailto: contato@exacta.inf.puc-rio.br" color="inherit" style={{ textDecoration: 'none' }}>contato@exacta.inf.puc-rio.br</Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item container className={classes.topIcons} md={7}>
                                        <LocationOn fontSize="small" />
                                        <LinkScroll
                                            to="address"
                                            spy={true}
                                            smooth={true}
                                            offset={-130}
                                            duration={1000}
                                        >
                                            <Typography className={classes.headerTopText}>
                                                Nosso endereço
                                            </Typography>
                                        </LinkScroll>
                                    </Grid>
                                </Grid>

                                <Grid item container md={3} spacing={1} justify="flex-end" alignItems="center" className={classes.logosHeader}>
                                    <Grid item>
                                        <Link href="http://www.inf.puc-rio.br/" target="_blank">
                                            <img src={imageDI} alt="Logo Departamento de Informática" height="auto" width="70%" />
                                        </Link>
                                    </Grid>
                                    <Grid item >
                                        <Link href="http://www.puc-rio.br/index.html" target="_blank" >
                                            <img src={imagePUC} alt="Logo PUC-Rio" height="auto" width="70%" />
                                        </Link>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Slide>
                </Hidden>
                <Grid container item md={12} className={classes.headerMenu} justify="space-evenly" alignItems="center">
                    <Grid container item md={10} className={classes.headerMenu} justify="space-evenly" alignItems="center">
                        <Grid item container md={3} xs={8} >

                            <LinkScroll
                                to="header"
                                spy={true}
                                smooth={true}
                                offset={-130}
                                duration={1000}
                            >
                                <img src={imageExacta} alt="Logo ExACTa" className={headerScroll ? classes.logoExactaScroll : classes.logoExacta} />
                            </LinkScroll>
                        </Grid>
                        <Grid item container justify="flex-end" md={7} xs={4}>
                            <Grid item>
                                <ThemeProvider theme={theme}>
                                    <Menu2 />
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Header;
