import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, ThemeProvider, Link, Typography, Button } from '@material-ui/core';
import MobileStepper from '@material-ui/core/MobileStepper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import imageBanner1 from '../../img/banner1.png';
import imageBanner6 from '../../img/banner6.png';
import imageBanner7 from '../../img/banner7.png';
import imageBanner8 from '../../img/banner8.png';
import imageBanner9 from '../../img/banner9.png';
import imageBanner10 from '../../img/banner10.png';
import imageBanner11 from '../../img/banner11.png';
import imageWatermark from '../../img/VETORES_desktop_b.svg';
import Email from '@material-ui/icons/Email';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5vh",
    },
    img: {
        display: 'block',
        overflow: 'hidden',
        width: '100%',
        height: "100vh",
    },
    stepper: {
        position: "relative",
        top: "-12vh",
        backgroundColor: "transparent",
    },
    buttonsContainer: {
        position: "absolute",
        width: "100%",
    },
    buttons: {
        display: "flex",
        justifyContent: "space-between",
    },
    button: {
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        color: "white",
        margin: "0 8px",
        '&:hover': {
            backgroundColor: "rgba(0, 0, 0, 0.50)",
        },
    },
    carouselTextContainer: {
        position: "absolute",
        maxWidth: "1200px",
        color: "#fff",
        '@media (max-width: 1200px)':{
            padding: "15vw",
        },
    },
    carouselText: {
        textShadow: "1px 1px 6px #000",
        display: "flex",
        fontSize: "2.2em",
        textAlign: "left",
        marginTop: "2vh",
    },
    carouselTextTitle: {
        textShadow: "1px 1px 6px #000",
        fontSize: "4.0em",
        textAlign: "left",
        display: "flex",
        marginTop: "2vh"
    },
    buttonCarouselContate: {
        border: "solid 1px #FFF",
        backgroundColor: "#010B4A8C",
        borderRadius: "6px",
        textTransform: 'none',
        color: "#FFF",
        '&:hover': {
            backgroundColor: '#261570',
        },
        zIndex: "30",
        marginTop: "5vh",
    },
    buttonIcon: {
        verticalAlign: "middle",
    },
    divWatermark: {
        backgroundColor: "transparent",
        width: '100%',
        position: "absolute",
    },
}));



function SwipeableTextMobileStepper() {
    const classes = useStyles();
    const theme = useTheme();

    const tutorialSteps = [
        {
            imgPath: () => (<img className={classes.img}
                src={imageBanner1}
                alt="ExACTa" />),
        },
        {
            imgPath: () => (<img className={classes.img}
                src={imageBanner6}
                alt="ExACTa" />),
        },
        {
            imgPath: () => (<img className={classes.img}
                src={imageBanner7}
                alt="ExACTa" />),
        },
        {
            imgPath: () => (<img className={classes.img}
                src={imageBanner8}
                alt="ExACTa" />),
        },
        {
            imgPath: () => (<img className={classes.img}
                src={imageBanner9}
                alt="ExACTa" />),
        },
        {
            imgPath: () => (<img className={classes.img}
                src={imageBanner10}
                alt="ExACTa" />),
        },
        {
            imgPath: () => (<img className={classes.img}
                src={imageBanner11}
                alt="ExACTa" />),
        }
    ];

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;

    const handleNext = () => {
        setActiveStep(prevActiveStep => (prevActiveStep + 1) % maxSteps);
        //onDotClick={handleStep}
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => (prevActiveStep - 1) < 0 ? (maxSteps - 1) : prevActiveStep - 1);
    };

    const handleStepChange = step => {
        setActiveStep(step);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
      };

    return (



        <div className={classes.root} >
            <ThemeProvider theme={theme}>
                    <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        interval={10000}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                        style={{ overflow: 'hidden' }}
                    >
                        {tutorialSteps.map((step, index) => (
                            <div key={index}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <step.imgPath></step.imgPath>
                                ) : null}
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                    <Grid className={classes.divWatermark}>
                        <img src={imageWatermark} width="100%" height="100%" alt="marca d'água"/>
                    </Grid>
                <Grid container className={classes.carouselTextContainer} >
                    <Grid item md={12} xs={12}>
                        <Typography className={classes.carouselTextTitle} >
                            Pesquisa e Desenvolvimento Ágil <br /> para Transformação Digital
                        </Typography>
                        <Typography className={classes.carouselText} >
                            Iniciativa da PUC-Rio. Líder em Integração com a Indústria na América Latina.
                        <br />
                            Vinculada ao Departamento de Informática. Excelência em Pesquisa - CAPES 7.
                        </Typography >
                    </Grid>
                    <Grid item container className={classes.carouselButton}>
                        <Link href="mailto: contato@exacta.inf.puc-rio.br" color="inherit" underline="none">
                            <Button variant="contained" className={classes.buttonCarouselContate} endIcon={<Email fontSize="small" />}>
                                Contate-nos
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </ThemeProvider>
                <div className={classes.buttonsContainer}>
                    <div className={classes.buttons}>
                        <IconButton
                            onClick={handleBack}
                            className={classes.button}
                        >
                            {theme.direction === "rtl" ? (
                                <KeyboardArrowRight />
                            ) : (
                                    <KeyboardArrowLeft />
                                )}
                        </IconButton>
                        <IconButton
                            onClick={handleNext}
                            className={classes.button}
                        >
                            {theme.direction === "rtl" ? (
                                <KeyboardArrowLeft />
                            ) : (
                                    <KeyboardArrowRight />
                                )}
                        </IconButton>
                    </div>
                </div>


                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="dots"
                    onClick={handleStep(activeStep)}
                    activeStep={activeStep}
                    className={classes.stepper}
                    backButton={<div />}
                    nextButton={<div />}
                />
                

        </div>
    );
}

export default SwipeableTextMobileStepper;