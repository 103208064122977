import React from 'react'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    address: {
        height: '55vh',
        '@media (max-width: 768px)': {
            height: '45vh',
        }
    }
}))

function Address(props) {

    const classes = useStyles();

    return (
        <Grid id="address">
            <Grid className={classes.address}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.201606280148!2d-43.23525558590774!3d-22.979612784974726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9bd54195d584b7%3A0x28f24b336b7d341!2sExACTa%2C%20PUC-Rio!5e0!3m2!1spt-BR!2sbr!4v1581041761588!5m2!1spt-BR!2sbr" 
                width="100%" height="100%" border="solid 1 #DCDCDC" allowFullScreen title='Maps ExACTa'></iframe>
            </Grid>           
        </Grid>
    );
}
export default Address;

