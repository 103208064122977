import React from 'react'
import { Grid, Typography, Link, GridList, GridListTile } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PaperCard from '../../molecule/cards/paperCard';
import * as paper from '../../utils/constants/publication';
import imageBackground from '../../img/fundo_publicacoes_2.png';

const useStyles = makeStyles(theme => ({
    publication: {
        width: '100%',
        backgroundImage: `url(${imageBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: "5vh",
        marginTop: "5vh",
    },

    publicationContent: {
        maxWidth: "100vw",
        padding: "5vw",
        height: "auto"

    },
    publicationsTitle: {
        fontSize: "1.4em",
        textAlign: "left",
        color: "#333457",
        fontWeight: "bold",
    },
    publicationsTitle2: {
        colo: "#333457",
        fontSize: "2.4em",
        textAlign: "left",
        fontWeight: "bold",
        marginTop: "2%",
        lineHeight: "28px",
    },
    publicationsText: {
        textAlign: "left",
        fontSize: "1.4em",
        color: "#333457",
        marginTop: "8%",
        marginBottom: "2vh"
    },
    buttonPublications: {
        textTransform: 'none',
        backgroundColor: "#fff",
        marginTop: "2%",
    },
    link: {
        '&:hover': {
            color: "#0C23AC",
        },
    },
    rootGridListParent: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        paddingRight: '5vw',
    },
    gridTile: {
        height: "100vh",
        width: "100vw"
    }
}))

function PublicationsMobile(props) {

    const classes = useStyles();

    return (

        <Grid container justify="center" id="publication" className={classes.publication}>
            <Grid item className={classes.publicationContent} xs={9}>
                <Grid container justify={"space-between"}>
                    <Grid item md={12} xs={12}>
                        <Typography className={classes.publicationsTitle}>
                            PUBLICAÇÕES
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Typography className={classes.publicationsTitle2}>
                            Leia os últimos Artigos Científicos e Publicações em Revistas
                        </Typography>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <Typography className={classes.publicationsText}>
                            Soluções envolvendo pesquisa de ponta, gerando conhecimento e resultando em publicações em veículos de alta reputação científica.
                        </Typography>
                    </Grid>
                </Grid>


                <div className={classes.rootGridListParent}>
                    <GridList className={classes.gridList} container cols={1.1} cellHeight="auto">
                        {
                            paper.PAPERS.map(papers => (
                                <GridListTile className={classes.gridTile}>
                                    <Link href={papers.link} target="_blank">
                                        <PaperCard
                                            image={window.location.origin + papers.image}
                                            title={papers.title}
                                            authors={papers.authors}
                                            date={papers.date}
                                        />
                                    </Link>
                                </GridListTile>
                            ))
                        }
                    </GridList>
                </div>
            </Grid>
        </Grid>
    );
}

export default PublicationsMobile;
