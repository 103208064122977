import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import imageBackground from '../../img/fundo_publicacoes_2.png';
import SlidePublication from '../../organism/slide/slidePublication';

const useStyles = makeStyles(theme => ({
    publication: {
        width: '100%',
        backgroundImage: `url(${imageBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        paddingTop: "15vh",
        paddingBottom: "3vh",
    },
    publicationsTitle: {
        fontSize: "1.4em",
        textAlign: "left",
        color: "#333457",
        fontWeight: "bold",
    },
    publicationsTitle2: {
        colo: "#333457",
        fontSize: "2.4em",
        textAlign: "left",
        fontWeight: "bold",
        marginTop: "2%",
        lineHeight: "28px",
    },
    publicationsText: {
        textAlign: "left",
        fontSize: "1.4em",
        color: "#333457",
        marginTop: "8%",
    },
    buttonPublications: {
        textTransform: 'none',
        backgroundColor: "#fff",
        marginTop: "2%",
    },
    link: {
        '&:hover': {
            color: "#0C23AC",
        },
    },
}))

function Publications(props) {

    const classes = useStyles();

    return (

        <Grid container justify="center" id="publication" className={classes.publication}>
            <Grid container item className={classes.publicationContent}  justify={"center"} xs={9}>
                <Grid container item justify={"space-between"} md={12}>
                    <Grid item md={12} xs={12}>
                        <Typography className={classes.publicationsTitle}>
                            PUBLICAÇÕES
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Typography className={classes.publicationsTitle2}>
                            Leia os últimos Artigos Científicos e Publicações em Revistas
                        </Typography>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <Typography className={classes.publicationsText}>
                            Soluções envolvendo pesquisa de ponta, gerando conhecimento e resultando em publicações em veículos de alta reputação científica.
                            </Typography>
                    </Grid>
                </Grid>
                <Grid container item justify="center" className={classes.publicationCards}>
                    <SlidePublication />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Publications;
